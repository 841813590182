import React from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp, FaEnvelope } from "react-icons/fa"; 
import { GoPerson } from "react-icons/go";

  function Content() {
    return (
       <div>
         <section class="">
              <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                     <img class="d-block w-100" src="cover/cv-1.jpg" alt="First slide" style={{height:"50"}}/>
                  </div>
                  <div class="carousel-item">
                     <img class="d-block w-100" src="cover/cv-1.jpg" alt="Second slide" style={{height:"50"}}/>
                  </div>
                </div>
               </div>
        </section>   

        <section class="bg-light" id="about">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12">
                 <h3 class="text-center mt-4 text-secondary">Know about us</h3>
               </div>
              </div>
             <div class="row">
                <p class="mt-4 mb-5">After being a successful seller to multiple eCommerce platforms, We are excited to announce that now we are officially a service provider partner with giant eCommerce platform Amazon. With our 7+ years of experience working with e-commerce, We can help you to create/register a new E-Commerce account and set up your digital store. We provide all E-commerce related solutions. </p>
             </div>   
          </div>   
        </section>   

        <section class="" id="services">   
         <div class="container">
             <div class="row">
              <div class="col-sm-12 col-md-12">
                 <h3 class="text-center mt-4 text-secondary">Our Services</h3>
               </div>
              </div>
              <div class="row">
                 <div class="col-sm-4 mb-5">
                    <div class="card h-100">
                       <img class="card-img-top" src="cards/1-ac-reg.jpg" alt=""/>
                       <div class="card-body">
                          <h4 class="card-title text-secondary">E-Commerce Account Registration</h4>
                          <p class="card-text text-secondary">Kickstart your selling online, We help to create E-commerce account with marketplace likes Amazon, Meesho, Flipkart.</p>
                       </div>
                       <div class="card-footer">
                          <a href="#contactus" class="btn btn-primary">Contact Us!</a>
                       </div>
                    </div>
                 </div>
                 <div class="col-sm-4 mb-5">
                    <div class="card h-100">
                       <img class="card-img-top" src="cards/2-product-listing.jpg" alt=""/>
                       <div class="card-body">
                          <h4 class="card-title text-secondary">Product Listing</h4>
                          <p class="card-text text-secondary">The success of an Online business depends on how well you list the products.Get in touch with us and get listed as per standards.</p>
                       </div>
                       <div class="card-footer">
                          <a href="#contactus" class="btn btn-primary">Contact Us!</a>
                       </div>
                    </div>
                 </div>
                 <div class="col-sm-4 mb-5">
                    <div class="card h-100">
                       <img class="card-img-top" src="cards/3-ac-mngmnt.jpg" alt=""/>
                       <div class="card-body">
                          <h4 class="card-title text-secondary">E-Commerce Account Management</h4>
                          <p class="card-text text-secondary">we will manage your account, when you can focus on products development.</p>
                       </div>
                       <div class="card-footer">
                          <a href="#contactus" class="btn btn-primary">Contact Us!</a>
                       </div>
                    </div>
                 </div>
              </div>
              <div class="row">
                 <div class="col-sm-4 mb-5">
                    <div class="card h-100">
                       <img class="card-img-top" src="cards/4-suspensionAc.jpg" alt=""/>
                       <div class="card-body">
                          <h4 class="card-title text-secondary">Suspension Appeal</h4>
                          <p class="card-text text-secondary">Has your account been suspended? Don’t panic! We will help you curate the best plan of action for account re-instatement with 100% money-back guarantee.</p>
                       </div>
                       <div class="card-footer">
                          <a href="#contactus" class="btn btn-primary">Contact Us!</a>
                       </div>
                    </div>
                 </div>
                 <div class="col-sm-4 mb-5">
                    <div class="card h-100">
                       <img class="card-img-top" src="cards/5-PaymentRecon.jpg" alt=""/>
                       <div class="card-body">
                          <h4 class="card-title text-secondary">Payment Reconciliation</h4>
                          <p class="card-text text-secondary">Still struggling to reconcile your payments online?. Contact us to get your total payment reconcile details.</p>
                       </div>
                       <div class="card-footer">
                          <a href="#contactus" class="btn btn-primary">Contact Us!</a>
                       </div>
                    </div>
                 </div>
                 <div class="col-sm-4 mb-5">
                    <div class="card h-100">
                       <img class="card-img-top" src="cards/6-gst.jpg" alt=""/>
                       <div class="card-body">
                          <h4 class="card-title text-secondary">Gst Filling And Registration</h4>
                          <p class="card-text text-secondary">Contact us to get new GST registration and GST filling with lowest rate.</p>
                       </div>
                       <div class="card-footer">
                          <a href="#contactus" class="btn btn-primary">Contact Us!</a>
                       </div>
                    </div>
                 </div>
              </div>
        </div>
       </section>  
    
       <section class="bg-dark mt-2" id="contactus">
           <div class="container">
              <div class="row">
                 <div class="col-sm-4">
                    <h4 class="mt-4 text-white">Address</h4>
                    <ul class="list-unstyled text-white">
                       <li>330, Apple Square,</li>
                       <li>Nr Lajamni Chowk,</li>
                       <li>Mota Varachha,</li>
                       <li>Surat - 394101</li>
                    </ul>
                 </div>
                 <div class="col-sm-4">
                    <h4 class="mt-4 text-white">Contact</h4>
                    <ul class="list-unstyled text-white">
                       <li><GoPerson /> Chirag Thummar</li>
                       <li>&ensp;&ensp;+91 7567175999</li><br></br>
                       <li><FaEnvelope /> contact@ecomnewgen.com</li>
                    </ul>
                 </div>
                 <div class="col-sm-4">
                    <h4 class="mt-4 text-white">Connect with us</h4>
                    <ul class="list-unstyled">
                       <br></br>
                       <li>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                          <a href='https://www.facebook.com/Ecomnewgen2021' target='_blank'> 
                              <FaFacebook size="1.2em" color="white"/>
                          </a>
                           &ensp;&ensp;
                           <a href='https://www.instagram.com/ecomnewgen/?utm_source=qr' target='_blank'> 
                              <FaInstagram size="1.2em" color="white"/>
                          </a>
                           &ensp;&ensp;
                           <a href='https://wa.me/+917567175999' target='_blank'> 
                              <FaWhatsapp size="1.2em" color="white"/>
                          </a>
                        </li>
                    </ul>
                 </div>
              </div>
           </div>

   </section> 
   </div>
    );
  }
  
  export default Content;