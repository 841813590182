import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Header() {
  return (
    <section class="navbar-dark bg-dark header">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <nav class="navbar navbar-expand-lg ">
            <div class="align-self-baseline">
              <img class="logo-img-top" src="Logo/final-JPEG.png" alt="" />
              </div>
              <a class="navbar-brand text-white" href="#">&ensp;&ensp;</a>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item ">
                    <a class="nav-link text-white" href="#about">About Us<span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-white" href="#services">Services<span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-white" href="#contactus">Contact Us<span class="sr-only">(current)</span></a>
                  </li>
                </ul>

              </div>
            </nav>
          </div>
        </div>
      </div>
    </section> 
  );
}

export default Header;
