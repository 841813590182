import React from 'react';


function Footer()
{
    return(
          <section class="bg-light">
             <div class="container">

               <div class="row">  
                <div class="col-sm-12">
                  <h6 class="text-black text-center mb-2 mt-1" size="0.2em">
                  ECOMNEWGEN @{new Date().getFullYear()}. All Rights Reserved.
                  </h6> 
                </div>  
                
               </div>
             </div>    
          </section>
    
    )
}

export default Footer;